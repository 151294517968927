import styled, { css } from "styled-components";
import { Badge, Button } from "@mui/material";
import React, { useRef, useState } from "react";
import { Fade } from "react-awesome-reveal";
import {
  Close,
  Graph,
  Hamburger,
  Logo,
  Memo,
  Recurring,
  Reminder,
  TaskGrouping,
  Linkage,
  ChatLogo,
} from "Icons";
import { COLORS } from "styles/Colors";
import Fonts from "styles/Fonts";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { useClickOutside } from "@react-hookz/web";
import ReactGA from "react-ga4";
import { useEffect } from "react";
import ReactPixel from "react-facebook-pixel";
import FooterStartButton from "components/FooterStartButton";
import { useLocation } from "react-router-dom";
import { AppStore } from "Icons/AppStore";
import { AppStoreText } from "Icons/AppStoreText";
import { GooglePlay } from "Icons/GooglePlay";
import { GooglePlayText } from "Icons/GooglePlayText";

interface Props {
  isMobile?: boolean;
  isTablet?: boolean;
  isSmallTablet?: boolean;
  isDesktop?: boolean;
  isBigDesktop?: boolean;
  language?: string;
}

const Chat = ({
  isMobile = false,
  isTablet = false,
  isSmallTablet = false,
  isBigDesktop = false,
  isDesktop = false,
  language,
}: Props) => {
  const [drawer, setDrawer] = useState(false);
  const refDrawer = useRef<HTMLDivElement>(null);
  const imgRef = useRef<HTMLImageElement>(null);
  const logoRef = useRef<HTMLImageElement>(null);
  const [isImgLoaded, setIsImgLoaded] = useState(false);
  const [render, setRender] = useState(false);
  const [isLogoLoaded, setIsLogoLoaded] = useState(false);
  const location = useLocation();

  const handleOpenDrawer = () => {
    setDrawer(true);
  };
  const handleCloseDrawer = () => {
    setDrawer(false);
  };

  useClickOutside(refDrawer, () => {
    setTimeout(() => {
      if (drawer) setDrawer(false);
    }, 100);
  });

  useEffect(() => {
    if (isImgLoaded && isLogoLoaded) {
      setTimeout(() => {
        setRender(true);
      }, 200);
    }
  }, []);

  useEffect(() => {
    if (!imgRef.current) return;

    // complete와 naturalHeight를 이용해 완전한 load를 판단하는 함수
    const updateStatus = (img: HTMLImageElement) => {
      const isLoaded = img.complete && img.naturalHeight !== 0;
      setIsImgLoaded(isLoaded);
    };

    // load 이벤트를 바라본다.
    // 익명 함수를 사용했기 때문에 once 속성을 사용해서 한번 실행 후 제거한다.
    imgRef.current.addEventListener(
      "load",
      () => updateStatus(imgRef.current as HTMLImageElement),
      { once: true }
    );
  }, [imgRef]);

  useEffect(() => {
    if (!logoRef.current) return;

    // complete와 naturalHeight를 이용해 완전한 load를 판단하는 함수
    const updateStatus = (img: HTMLImageElement) => {
      const isLoaded = img.complete && img.naturalHeight !== 0;
      setIsLogoLoaded(isLoaded);
    };

    // load 이벤트를 바라본다.
    // 익명 함수를 사용했기 때문에 once 속성을 사용해서 한번 실행 후 제거한다.
    logoRef.current.addEventListener(
      "load",
      () => updateStatus(logoRef.current as HTMLImageElement),
      { once: true }
    );
  }, [logoRef]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const handleRegisterGtag = (label?: string) => {
    ReactGA.event({
      category: "Button",
      action: "Press Register Button",
      label: label ? label : "Register Button",
    });
  };

  const slide: {
    logo: string;
    text: any;
    image: any;
    name: string;
    company: string;
  }[] = [
    {
      logo: require("../images/enactus.png"),
      text:
        language === "ko" ? (
          isMobile ? (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span style={{ color: COLORS.sub2, fontWeight: 700 }}>
                하루를 계획하는 문제를 해결해줬어요.
              </span>
              <span>SLASH로 빠르게 계획하면</span>
              <span>하루를 자신감 있게 시작할 수 있어요.</span>
            </div>
          ) : isDesktop ? (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span style={{ color: COLORS.sub2, fontWeight: 700 }}>
                하루를 계획하는 문제를 해결해줬어요.
              </span>
              <span>
                SLASH로 빠르게 계획하면 하루를 자신감 있게 시작할 수 있어요.
              </span>
            </div>
          ) : (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span style={{ color: COLORS.sub2, fontWeight: 700 }}>
                하루를 계획하는 문제를 해결해줬어요.
              </span>
              <span>SLASH로 빠르게 계획하면</span>
              <span>하루를 자신감 있게 시작할 수 있어요.</span>
            </div>
          )
        ) : isMobile ? (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span style={{ color: COLORS.sub2, fontWeight: 700 }}>
              SLASH has solved the problem of planning
            </span>
            <span>
              <span style={{ color: COLORS.sub2, fontWeight: 700 }}>
                my day.
              </span>
              &nbsp;
              <span>When I plan my day with SLASH,</span>
            </span>
            <span>I can start confidently.</span>
          </div>
        ) : isDesktop ? (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span style={{ color: COLORS.sub2, fontWeight: 700 }}>
              SLASH has solved the problem of planning my day.
            </span>
            <span>When I plan my day with SLASH, I can start confidently.</span>
          </div>
        ) : (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span style={{ color: COLORS.sub2, fontWeight: 700 }}>
              SLASH has solved the problem of planning my day.
            </span>
            <span>When I plan my day with SLASH, I can start confidently.</span>
          </div>
        ),
      image: require("../images/enactusRecommendation.png"),
      name: language === "ko" ? "이고은" : "Koeun Lee",
      company:
        language === "ko"
          ? "Enactus Korea, 사무국장"
          : "Enactus Korea, President",
    },
    {
      logo: require("../images/onespirits.png"),
      text:
        language === "ko" ? (
          isMobile ? (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span>
                <span>무엇보다도</span> &nbsp;
                <span style={{ color: COLORS.sub2, fontWeight: 700 }}>
                  배우거나 세팅할 필요 없이
                </span>
              </span>
              <span>
                <span style={{ color: COLORS.sub2, fontWeight: 700 }}>
                  바로 쓸 수 있어 좋아요.
                </span>
                &nbsp;
                <span>SLASH 덕분에</span>
              </span>
              <span>업무 정리로 고민하는 시간이 줄었어요.</span>
            </div>
          ) : isDesktop ? (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span>
                <span>무엇보다도</span>&nbsp;
                <span style={{ color: COLORS.sub2, fontWeight: 700 }}>
                  배우거나 세팅할 필요 없이 바로 쓸 수 있어 좋아요.
                </span>
              </span>
              <span>SLASH 덕분에 업무 정리로 고민하는 시간이 줄었어요.</span>
            </div>
          ) : (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span>
                <span>무엇보다도</span> &nbsp;
                <span style={{ color: COLORS.sub2, fontWeight: 700 }}>
                  배우거나 세팅할 필요 없이
                </span>
              </span>
              <span style={{ color: COLORS.sub2, fontWeight: 700 }}>
                바로 쓸 수 있어 좋아요.
              </span>
              <span>SLASH 덕분에 업무 정리로 고민하는 시간이 줄었어요.</span>
            </div>
          )
        ) : isMobile ? (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>
              <span>What I love most is</span> &nbsp;
              <span style={{ color: COLORS.sub2, fontWeight: 700 }}>
                that there’s no need to learn
              </span>
            </span>
            <span style={{ color: COLORS.sub2, fontWeight: 700 }}>
              or set anything up—you can start using it right
            </span>
            <span>
              <span style={{ color: COLORS.sub2, fontWeight: 700 }}>away.</span>
              &nbsp;
              <span>Thanks to SLASH, I spend less time </span>
            </span>
            <span>worrying about organizing my tasks.</span>
          </div>
        ) : isDesktop ? (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>
              <span>What I love most is</span>&nbsp;
              <span style={{ color: COLORS.sub2, fontWeight: 700 }}>
                that there’s no need to learn or set anything up
              </span>
            </span>
            <span>
              <span style={{ color: COLORS.sub2, fontWeight: 700 }}>
                —you can start using it right away.
              </span>
              &nbsp;
              <span>Thanks to SLASH, I spend less </span>&nbsp;
            </span>
            <span>time worrying about organizing my tasks.</span>
          </div>
        ) : (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>
              <span>What I love most is</span>
              <span style={{ color: COLORS.sub2, fontWeight: 700 }}>
                that there’s no need to learn or set anything up
              </span>
            </span>
            <span>
              <span style={{ color: COLORS.sub2, fontWeight: 700 }}>
                —you can start using it right away.
              </span>
              &nbsp;
              <span>Thanks to SLASH, I spend less </span>&nbsp;
            </span>
            <span>time worrying about organizing my tasks.</span>
          </div>
        ),
      image: require("../images/onespiritsRecommendation.png"),
      name: language === "ko" ? "차미지" : "Miji Cha",
      company:
        language === "ko"
          ? "원스피리츠, 경영관리본부 리드"
          : "One Spirits, Lead",
    },
    {
      logo: require("../images/genieworks.png"),
      text:
        language === "ko" ? (
          isDesktop ? (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span>
                지금 집중할 일과 나중에 할 일을 빠르게 정리해서 마음 편히 일할
                수 있어요.
              </span>
              <span style={{ color: COLORS.sub2, fontWeight: 700 }}>
                업무가 쏟아질듯 많은 사람에게 추천해요.
              </span>
            </div>
          ) : (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span>지금 집중할 일과 나중에 할 일을</span>
              <span>빠르게 정리해서 마음 편히 일할 수 있어요.</span>
              <span style={{ color: COLORS.sub2, fontWeight: 700 }}>
                업무가 쏟아질듯 많은 사람에게 추천해요.
              </span>
            </div>
          )
        ) : isMobile ? (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>I can quickly sort what to focus on now and what</span>
            <span>to do later, making it easier to work with peace </span>
            <span>
              <span>of mind.</span>&nbsp;
              <span style={{ color: COLORS.sub2, fontWeight: 700 }}>
                with a heavy workload.
              </span>
            </span>
            <span style={{ color: COLORS.sub2, fontWeight: 700 }}>
              with a heavy workload.
            </span>
          </div>
        ) : isDesktop ? (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>
              I can quickly sort what to focus on now and what to do later,
            </span>
            <span>
              <span>making it easier to work with peace of mind.</span>
              &nbsp;
              <span style={{ color: COLORS.sub2, fontWeight: 700 }}>
                I highly recommend it
              </span>
            </span>
            <span style={{ color: COLORS.sub2, fontWeight: 700 }}>
              to anyone with a heavy workload.
            </span>
          </div>
        ) : (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>
              I can quickly sort what to focus on now and what to do later,
            </span>
            <span>
              <span>making it easier to work with peace of mind.</span>&nbsp;
              <span style={{ color: COLORS.sub2, fontWeight: 700 }}>
                I highly
              </span>
            </span>
            <span style={{ color: COLORS.sub2, fontWeight: 700 }}>
              recommend it to anyone with a heavy workload.
            </span>
          </div>
        ),
      image: require("../images/genieworksRecommendation.png"),
      name: language === "ko" ? "홍지은" : "Jieun Hong",
      company: language === "ko" ? "지니웍스, PM" : "Genie Works, PM",
    },
    {
      logo: require("../images/millie.png"),
      text:
        language === "ko" ? (
          isMobile ? (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span>일을 바쁘게 했는데 뭘 했는지</span>
              <span>모를 때가 있잖아요. SLASH를 쓰면</span>
              <span style={{ color: COLORS.sub2, fontWeight: 700 }}>
                성취를 시각적으로 확인할 수 있어서
              </span>
              <span>하루를 채워가는 느낌이 들어요.</span>
            </div>
          ) : isDesktop ? (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span>
                <span>
                  일을 바쁘게 했는데 뭘 했는지 모를 때가 있잖아요. SLASH를 쓰면
                </span>
                &nbsp;
                <span style={{ color: COLORS.sub2, fontWeight: 700 }}>
                  성취를
                </span>
              </span>
              <span>
                <span style={{ color: COLORS.sub2, fontWeight: 700 }}>
                  시각적으로 확인할 수 있어서
                </span>
                &nbsp;
                <span>하루를 채워가는 느낌이 들어요.</span>
              </span>
            </div>
          ) : (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span>
                <span>
                  일을 바쁘게 했는데 뭘 했는지 모를 때가 있잖아요. SLASH를 쓰면
                </span>
                &nbsp;
                <span style={{ color: COLORS.sub2, fontWeight: 700 }}>
                  성취를
                </span>
              </span>
              <span>
                <span style={{ color: COLORS.sub2, fontWeight: 700 }}>
                  시각적으로 확인할 수 있어서
                </span>
                &nbsp;
                <span>하루를 채워가는 느낌이 들어요.</span>
              </span>
            </div>
          )
        ) : isMobile ? (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>{`You know those days where you're busy`}</span>
            <span>but unsure what you actually accomplished? </span>
            <span>
              <span>With SLASH,</span>
              &nbsp;
              <span style={{ color: COLORS.sub2, fontWeight: 700 }}>
                you can visually track your
              </span>
            </span>
            <span>
              <span style={{ color: COLORS.sub2, fontWeight: 700 }}>
                progress,
              </span>
              &nbsp;
              <span>{`and it feels like you're really making`}</span>
            </span>
            <span>the most of your day.</span>
          </div>
        ) : isDesktop ? (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>{`You know those days where you're busy but unsure what`}</span>
            <span>
              <span>you actually accomplished? With SLASH,</span>
              &nbsp;
              <span style={{ color: COLORS.sub2, fontWeight: 700 }}>
                you can visually track
              </span>
            </span>
            <span>
              <span>you can visually track your progress,</span>
              &nbsp;
              <span style={{ color: COLORS.sub2, fontWeight: 700 }}>
                {`and it feels like you're really making`}
              </span>
            </span>
            <span>the most of your day.</span>
          </div>
        ) : (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>{`You know those days where you're busy but unsure what`}</span>
            <span>
              <span>you actually accomplished? With SLASH,</span>
              &nbsp;
              <span style={{ color: COLORS.sub2, fontWeight: 700 }}>
                you can visually track
              </span>
            </span>
            <span>
              <span>you can visually track your progress,</span>
              &nbsp;
              <span style={{ color: COLORS.sub2, fontWeight: 700 }}>
                {`and it feels like you're really making`}
              </span>
            </span>
            <span>the most of your day.</span>
          </div>
        ),
      image: require("../images/millieRecommendation.png"),
      name: language === "ko" ? "조윤성" : "Yunseung Cho",
      company: language === "ko" ? "밀리의 서재, PM" : "Millie, PM",
    },
    // {
    //   logo: require("../images/enactus.png"),
    //   text: isMobile
    //     ? "하루를 계획하는 문제를 해결해줬어요.\nSLASH로 빠르게 계획하면\n하루를 자신감 있게 시작할 수 있어요."
    //     : "하루를 계획하는 문제를 해결해줬어요.\nSLASH로 빠르게 계획하면 하루를 자신감 있게 시작할 수 있어요.",
    //   image: require("../images/recommend.png"),
    //   name: "서진솔",
    //   company: "Enactus Korea, 사무국장",
    // },
  ];

  const feature = [
    {
      icon: Linkage,
      title: language === "ko" ? "연동" : "Integration",
      description:
        language === "ko"
          ? isTablet
            ? "여러 툴을 돌아다닐 필요 없어요.\nNotion, Slack의 업무를\n한 곳에서 확인할 수 있어요."
            : "여러 툴을 돌아다닐 필요 없어요. Notion,\nSlack의 업무를 한 곳에서 확인할 수 있어요."
          : "No need to switch between multiple tools—manage tasks all in one place.",
    },
    {
      icon: Recurring,
      title: language === "ko" ? "반복 업무" : "Routine",
      description:
        language === "ko"
          ? isSmallTablet
            ? "반복되는 업무와 지켜야 할 습관을\n관리하세요."
            : isTablet
            ? "반복되는 업무와 지켜야 할\n습관을 관리하세요."
            : "반복되는 업무와 지켜야 할 습관을 관리하세요."
          : "Manage recurring tasks and build habits you want to keep. ",
    },
    {
      icon: Memo,
      title: language === "ko" ? "메모" : "Memo",
      description:
        language === "ko"
          ? isTablet
            ? "To-do list, 캘린더와 연동되\n메모를 경험해보세요."
            : "To-do list, 캘린더와 연동되는 메모를\n경험해보세요."
          : "Experience memos that sync with your  to-do list and calendar.",
    },
    {
      icon: Reminder,
      title: language === "ko" ? "리마인더" : "Reminder",
      description:
        language === "ko"
          ? isDesktop
            ? "잊지 말아야 할 것은\nSlack으로 알림을 받아봐요."
            : "잊지 말아야 할 것은 Slack으로\n알림을 받아봐요."
          : `Get notified on Slack so you never miss what’s important.`,
    },
    {
      icon: TaskGrouping,
      title: language === "ko" ? "태스크 그루핑" : "Task Grouping",
      description:
        language === "ko"
          ? isDesktop
            ? "비슷한 업무들을 클릭 몇 번과\n단축키로 묶어보세요."
            : "비슷한 업무들을 클릭 몇 번과\n단축키로 묶어보세요."
          : "Group similar tasks with just a few clicks or keyboard shortcuts.",
    },
    {
      icon: Graph,
      title: language === "ko" ? "통계" : "Statistics",
      description:
        language === "ko"
          ? isTablet
            ? "내가 어디에 시간을 많이\n투자하고 있는지 시각적으로\n확인해보세요."
            : "내가 어디에 시간을 많이 투자하고\n있는지 시각적으로 확인해보세요."
          : `Visually see where you’re investing most of your time.`,
    },
  ];

  const [visible, setVisible] = useState({
    objective: false,
    plan: false,
    timebox: false,
    retrospect: false,
  });

  const getCookieValue = (key: string) => {
    const cookieKey = key + "=";
    let result = "";
    const cookieArr = document.cookie.split(";");

    for (let i = 0; i < cookieArr.length; i++) {
      if (cookieArr[i][0] === " ") {
        cookieArr[i] = cookieArr[i].substring(1);
      }

      if (cookieArr[i].indexOf(cookieKey) === 0) {
        result = cookieArr[i].slice(cookieKey.length, cookieArr[i].length);
        return result;
      }
    }
    return result;
  };

  return (
    <Container isBigDesktop={isBigDesktop}>
      <Main className="main">
        <Fade direction="up" triggerOnce={true} duration={1500}>
          <MainTextWrapper isMobile={isMobile}>
            <Badge
              sx={{
                backgroundColor: `${COLORS.sub3}`,
                color: `${COLORS.brand1}`,
                padding: "6px 14px",
                borderRadius: "16px",
                fontSize: isMobile ? `min(4vw, 13px)` : `min(2vw, 24px)`,
                lineHeight: isMobile ? `min(4vw, 20px)` : `min(2vw, 33px)`,
                fontWeight: 700,
              }}
            >
              ✨ AI Sidekick
            </Badge>
            <MainText isMobile={isMobile}>
              {language === "ko" ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <span
                    style={{
                      fontSize: isMobile
                        ? "min(7vw, 24px)"
                        : `min(4.5vw, 54px)`,
                      fontWeight: 700,
                      lineHeight: isMobile
                        ? `
                        min(11vw,36px)
                    `
                        : `min(8vw, 91px)`,
                      letterSpacing: "-0.25px",
                    }}
                  >
                    <span
                      style={{
                        color: COLORS.sub2,
                      }}
                    >
                      {"알아서 정리"}
                    </span>
                    <span
                      style={{
                        color: `${COLORS.gray800}`,
                      }}
                    >
                      {`되는 '나와의 채팅'`}
                    </span>
                  </span>
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: isMobile
                      ? language === "ko"
                        ? "min(7vw, 24px)"
                        : "min(7vw, 22px"
                      : `min(4.5vw, 54px)`,
                    fontWeight: 700,
                    lineHeight: isMobile
                      ? `
                    min(11vw,36px)
                `
                      : `min(8vw, 91px)`,
                    letterSpacing: "-0.25px",
                  }}
                >
                  <span>Knowledge management,</span>
                  <span style={{ color: COLORS.sub2, textAlign: "center" }}>
                    as easy as chatting with a friend
                  </span>
                </div>
              )}

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  color: `${COLORS.gray500}`,
                  fontWeight: "400",
                  fontSize: isMobile ? `min(7vw, 13px)` : `min(2vw, 20px)`,
                  lineHeight: isMobile ? "20px" : "30px",
                  letterSpacing: "-0.25px",
                  whiteSpace: "pre-line",
                  marginTop: isMobile ? "8px" : "12px",
                }}
              >
                {language === "ko" ? (
                  <span>
                    슬래시챗은 생각나는대로 적어두면 <br />
                    알아서 정리해주는
                    <span style={{ color: COLORS.sub2, fontWeight: 700 }}>
                      {" "}
                      AI 업무 비서
                    </span>
                    입니다.
                  </span>
                ) : (
                  `SLASH Chat intuitively organizes\nyour thoughts as you jot them down.`
                )}
              </div>
            </MainText>
          </MainTextWrapper>
        </Fade>
        <Fade
          direction="up"
          triggerOnce={true}
          duration={1500}
          style={isMobile ? { marginTop: "50px" } : {}}
        >
          <MainImageWrapper isMobile={isMobile}>
            <div
              style={
                isMobile
                  ? {
                      textAlign: "center",
                    }
                  : { display: "flex", flexDirection: "column" }
              }
            >
              <span
                style={{
                  fontSize: isMobile ? "min(8vw, 22px)" : "min(4vw, 54px)",
                  lineHeight: isMobile ? "min(11vw, 32px)" : "min(6vw, 81px)",
                }}
              >
                💬
              </span>
              <span
                style={{
                  fontWeight: 700,
                  fontSize: isMobile ? "min(8vw, 22px)" : "min(4vw, 54px)",
                  lineHeight: isMobile ? "min(11vw, 32px)" : "min(6vw, 81px)",
                  letterSpacing: "-0.25px",
                  marginLeft: isMobile ? "4px" : "0px",
                }}
              >
                {language === "ko" ? (
                  <>
                    {" "}
                    <span
                      style={{
                        color: `${COLORS.brand1}`,
                      }}
                    >
                      {`'나와의 채팅'`}
                    </span>
                    <span>에 보내는 메모들,</span>
                    <br />
                    <span>쌓아만 두고 있지 않나요?</span>
                  </>
                ) : (
                  <>
                    <span>Do</span>{" "}
                    <span
                      style={{
                        color: `${COLORS.brand1}`,
                      }}
                    >
                      your notes
                    </span>{" "}
                    <span>end up</span>
                    <br />
                    <span>scattered and forgotten?</span>
                  </>
                )}
              </span>
            </div>
            <img
              src={require(language === "ko"
                ? "../images/chatMain.png"
                : "images/chatMain_en.png")}
              width={isMobile ? "80%" : "30%"}
              style={{
                marginTop: isMobile ? "28px" : "50px",
                marginLeft: isMobile ? "0px" : "60px",
              }}
            />
          </MainImageWrapper>
        </Fade>
        <Fade
          direction="up"
          triggerOnce={true}
          duration={1500}
          style={isMobile ? { marginTop: "50px" } : {}}
        >
          <MainChatTextWrapper
            style={{
              margin: isMobile ? "110px 0px" : "170px 0px",
              padding: "60px 0px",
            }}
          >
            <span
              style={{
                textAlign: "center",
                fontWeight: 700,
                fontSize: isMobile ? "min(6vw, 32px)" : "min(4vw, 54px)",
                lineHeight: isMobile ? "min(9vw, 46px)" : "min(6vw, 81px)",
                letterSpacing: "-0.25px",
              }}
            >
              {language === "ko" ? (
                <>
                  ✨<span style={{ color: COLORS.sub2 }}>정리</span>
                  <span>는 이제</span>
                  <br />
                  <span style={{ color: COLORS.sub2 }}>슬래시챗</span>
                  <span>에게 맡겨요</span>
                </>
              ) : (
                <>
                  ✨<span>Leave the</span>{" "}
                  <span style={{ color: COLORS.sub2 }}>organizing</span>
                  <br />
                  <span style={{ color: COLORS.sub2 }}>to SLASH Chat</span>
                </>
              )}
            </span>
          </MainChatTextWrapper>
        </Fade>

        <Wrapper
          isMobile={isMobile}
          isSmallTablet={isSmallTablet}
          isTablet={isTablet}
        >
          <MainFeature
            isMobile={isMobile}
            isSmallTablet={isSmallTablet}
            isTablet={isTablet}
            isVisibleText={visible.plan}
          >
            <div
              className="feature-text-wrapper"
              style={{
                marginLeft: isMobile ? "0px" : "100px",
              }}
            >
              <Fade
                direction="up"
                cascade
                triggerOnce
                damping={0.1}
                duration={1500}
                onVisibilityChange={(isVisible) => {
                  setVisible({ ...visible, plan: isVisible });
                }}
              >
                <div className="feature-text-title">
                  <FeatureTextIcon
                    style={{
                      fontSize: isMobile ? 16 : 20,
                    }}
                  >
                    💬
                  </FeatureTextIcon>
                  <Fonts.H2
                    style={{
                      color: `${COLORS.gray500}`,
                      margin: "0px 0px 0px 8px",
                      fontSize: isMobile ? 16 : 20,
                    }}
                  >
                    {language === "ko" ? "채팅" : "Chat"}
                  </Fonts.H2>
                </div>
                <div className="feature-text">
                  <Fonts.H1
                    style={{
                      fontSize: isMobile ? "min(7vw, 24px)" : `min(3vw, 40px)`,
                      lineHeight: isMobile
                        ? "min(10vw, 36px)"
                        : "min(4vw, 60px)",
                      margin: isMobile ? "12px 0px" : "20px 0px",
                    }}
                  >
                    {language === "ko" ? (
                      <span>
                        <span>{`'나와의 채팅'으로`}</span>
                        <br />
                        <span
                          style={{
                            color: `${COLORS.sub2}`,
                          }}
                        >
                          쉽고 빠르게 메모
                        </span>
                        <span>해요</span>
                      </span>
                    ) : isMobile ? (
                      <span>
                        <span
                          style={{
                            color: `${COLORS.sub2}`,
                          }}
                        >
                          Quickly jot down ideas
                        </span>{" "}
                        <span>like</span>
                        <br />
                        <span>{`you're chatting`}</span>{" "}
                        <span>with a friend</span>
                      </span>
                    ) : (
                      <span>
                        <span
                          style={{
                            color: `${COLORS.sub2}`,
                          }}
                        >
                          Quickly jot down ideas
                        </span>
                        <br />
                        <span>{`like you're chatting`}</span>
                        <br />
                        <span>with a friend</span>
                      </span>
                    )}
                  </Fonts.H1>
                </div>
                <div
                  className="feature-text"
                  style={{
                    color: COLORS.gray500,
                    fontWeight: 500,
                    fontSize: isMobile ? "min(5vw, 16px)" : "min(2vw, 20px)",
                    lineHeight: isMobile ? "min(7vw, 24px)" : "min(3vw, 30px)",
                  }}
                >
                  {language === "ko" ? (
                    <span>
                      {isMobile ? (
                        <span>
                          할 일, 아이디어, 링크 등 머릿속 무엇이든
                          <br />
                          적고 잊어버리세요.
                        </span>
                      ) : (
                        <span>
                          할 일, 아이디어, 링크 등 머릿속 무엇이든 적고
                          <br />
                          잊어버리세요.
                        </span>
                      )}
                    </span>
                  ) : (
                    <span>
                      Tasks, ideas, links—jot down anything
                      <br />
                      and forget about it.
                    </span>
                  )}
                </div>
              </Fade>
            </div>
            <MainFeatureImgWrapper
              isMobile={isMobile}
              isSmallTablet={isSmallTablet}
              isTablet={isTablet}
              style={{ order: isDesktop ? -1 : 1 }}
            >
              <Fade direction="up" delay={100} triggerOnce duration={1500}>
                <img
                  src={require(language === "ko"
                    ? "../images/chatting.png"
                    : "images/chatting_en.png")}
                  width={isBigDesktop ? "100%" : isMobile ? "100%" : "130%"}
                  height="auto"
                />
              </Fade>
            </MainFeatureImgWrapper>
          </MainFeature>
        </Wrapper>

        <Wrapper
          isMobile={isMobile}
          isSmallTablet={isSmallTablet}
          isTablet={isTablet}
        >
          <MainFeature
            isMobile={isMobile}
            isSmallTablet={isSmallTablet}
            isTablet={isTablet}
            isVisibleText={visible.timebox}
          >
            <div
              className="feature-text-wrapper"
              style={{
                marginRight: isMobile || language === "ko" ? "0px" : "120px",
              }}
            >
              <Fade
                direction="up"
                cascade
                triggerOnce
                damping={0.1}
                duration={1500}
                onVisibilityChange={(isVisible) => {
                  setVisible({ ...visible, timebox: isVisible });
                }}
              >
                <div className="feature-text-title">
                  <FeatureTextIcon
                    style={{
                      fontSize: isMobile ? 16 : 20,
                    }}
                  >
                    ✨
                  </FeatureTextIcon>
                  <Fonts.H2
                    style={{
                      color: `${COLORS.gray500}`,
                      margin: "0px 0px 0px 8px",
                      fontSize: isMobile ? 16 : 20,
                    }}
                  >
                    {language === "ko" ? "자동 정리" : "Self-organizing"}
                  </Fonts.H2>
                </div>
                <div className="feature-text">
                  <Fonts.H1
                    style={{
                      fontSize: isMobile
                        ? "min(7vw, 24px)"
                        : `min(3vw, ${language === "ko" ? "40px" : "36px"})`,
                      lineHeight: isMobile
                        ? "min(10vw, 36px)"
                        : "min(4vw, 60px)",
                      margin: isMobile ? "12px 0px" : "20px 0px",
                    }}
                  >
                    {language === "ko" ? (
                      <span>
                        슬래시챗이
                        <br />
                        <span
                          style={{
                            color: `${COLORS.sub2}`,
                          }}
                        >
                          알아서 정리
                        </span>
                        해드려요
                      </span>
                    ) : (
                      <span>
                        <span>SLASH Chat</span>{" "}
                        <span
                          style={{
                            color: `${COLORS.sub2}`,
                          }}
                        >
                          intelligently
                        </span>
                        <br />
                        <span
                          style={{
                            color: `${COLORS.sub2}`,
                          }}
                        >
                          sorted
                        </span>{" "}
                        <span>it out for you</span>
                      </span>
                    )}
                  </Fonts.H1>
                </div>
                <div
                  className="feature-text"
                  style={{
                    color: COLORS.gray500,
                    fontSize: isMobile ? "min(5vw, 16px)" : "min(2vw, 20px)",
                    lineHeight: isMobile ? "min(7vw, 24px)" : "min(2vw, 30px)",
                  }}
                >
                  {language === "ko" ? (
                    <span>태스크, 메모, 링크로 자동으로 분류해줘요.</span>
                  ) : isMobile ? (
                    <span>
                      Tasks, notes, and links—
                      <br />
                      automatically categorized.
                    </span>
                  ) : (
                    <span>
                      Tasks, notes, and links—automatically categorized.
                    </span>
                  )}
                </div>
              </Fade>
            </div>

            <MainFeatureImgWrapper
              isMobile={isMobile}
              isSmallTablet={isSmallTablet}
              isTablet={isTablet}
            >
              <Fade
                direction="up"
                delay={100}
                triggerOnce
                duration={1500}
                style={
                  isMobile
                    ? {
                        display: "flex",
                        justifyContent: "center",
                      }
                    : {}
                }
              >
                <img
                  src={require(language === "ko"
                    ? "../images/autoOrganize.png"
                    : "../images/autoOrganize_en.png")}
                  width={
                    isBigDesktop
                      ? language === "ko"
                        ? "130%"
                        : "100%"
                      : isMobile
                      ? language === "ko"
                        ? "130%"
                        : "100%"
                      : language === "ko"
                      ? "190%"
                      : "140%"
                  }
                  height="auto"
                  style={
                    isMobile && language === "en"
                      ? {
                          marginLeft: "78px",
                        }
                      : {}
                  }
                />
              </Fade>
            </MainFeatureImgWrapper>
          </MainFeature>
        </Wrapper>

        <Wrapper
          isMobile={isMobile}
          isSmallTablet={isSmallTablet}
          isTablet={isTablet}
        >
          <MainFeature
            isMobile={isMobile}
            isSmallTablet={isSmallTablet}
            isTablet={isTablet}
            isVisibleText={visible.retrospect}
          >
            <div
              className="feature-text-wrapper"
              style={{
                marginLeft: isMobile ? "0px" : "100px",
              }}
            >
              <Fade
                direction="up"
                cascade
                triggerOnce
                damping={0.1}
                duration={1500}
                onVisibilityChange={(isVisible) => {
                  setVisible({ ...visible, retrospect: isVisible });
                }}
              >
                <div className="feature-text-title">
                  <FeatureTextIcon
                    style={{
                      fontSize: isMobile ? 16 : 20,
                    }}
                  >
                    🧠
                  </FeatureTextIcon>
                  <Fonts.H2
                    style={{
                      color: `${COLORS.gray500}`,
                      margin: "0px 0px 0px 8px",
                      fontSize: isMobile ? 16 : 20,
                    }}
                  >
                    {language === "ko" ? "패턴 학습" : "Pattern Learning"}
                  </Fonts.H2>
                </div>
                <div className="feature-text">
                  <Fonts.H1
                    style={{
                      fontSize: isMobile ? "min(7vw, 24px)" : `min(3vw,  40px)`,
                      lineHeight: isMobile
                        ? "min(10vw, 36px)"
                        : "min(4vw, 60px)",
                      margin: isMobile ? "12px 0px" : "20px 0px",
                    }}
                  >
                    {language === "ko" ? (
                      <span>
                        내 정리 패턴을 학습해
                        <br />
                        <span
                          style={{
                            color: `${COLORS.sub2}`,
                          }}
                        >
                          점점 더 정확
                        </span>
                        해져요
                      </span>
                    ) : (
                      <span>
                        <span>Learn my organization</span>
                        <br />
                        <span>patterns and</span>{" "}
                        <span
                          style={{
                            color: `${COLORS.sub2}`,
                          }}
                        >
                          become more
                        </span>
                        <br />
                        <span
                          style={{
                            color: `${COLORS.sub2}`,
                          }}
                        >
                          accurate
                        </span>{" "}
                        <span>over time</span>
                      </span>
                    )}
                  </Fonts.H1>
                </div>
                <div
                  className="feature-text"
                  style={{
                    color: COLORS.gray500,
                    fontSize: isMobile ? "min(4.7vw, 16px)" : "min(2vw, 20px)",
                    lineHeight: isMobile ? "min(7vw, 24px)" : "min(2vw, 30px)",
                  }}
                >
                  {language === "ko" ? (
                    <span>
                      분류를 직접 수정하거나 태스크, 메모를 입력하면
                      <br />
                      슬래시챗이 배울 수 있어요.
                    </span>
                  ) : isMobile ? (
                    <span>
                      By directly adjusting categories or
                      <br />
                      entering tasks and memos,
                      <br />
                      SLASH Chat can learn from you.
                    </span>
                  ) : (
                    <span>
                      By directly adjusting categories or entering tasks
                      <br />
                      and memos, SLASH Chat can learn from you.
                    </span>
                  )}
                </div>
              </Fade>
            </div>

            <MainFeatureImgWrapper
              isMobile={isMobile}
              isSmallTablet={isSmallTablet}
              isTablet={isTablet}
              style={{ order: isDesktop ? -1 : 1 }}
            >
              <Fade
                direction="up"
                triggerOnce={true}
                delay={100}
                duration={1500}
                style={
                  isMobile
                    ? {
                        display: "flex",
                        justifyContent: "center",
                      }
                    : {}
                }
              >
                <img
                  src={require(language === "ko"
                    ? "../images/pattern.png"
                    : "../images/pattern_en.png")}
                  width={
                    isBigDesktop
                      ? language === "ko"
                        ? "80%"
                        : "90%"
                      : isMobile
                      ? "73%"
                      : "110%"
                  }
                  height="auto"
                />
              </Fade>
            </MainFeatureImgWrapper>
          </MainFeature>
        </Wrapper>
        <SlashSubFeatureWrapper
          isMobile={isMobile}
          isSmallTablet={isSmallTablet}
          isTablet={isTablet}
          isBigDesktop={isBigDesktop}
        >
          <Fade direction="up" triggerOnce={true} duration={1500}>
            <SlashSubFeatureHeader
              style={{
                textAlign: "center",
                fontSize: isMobile ? `min(7vw, 24px)` : `min(3vw, 43px)`,
                lineHeight: isMobile ? `min(10vw, 36px)` : `min(5vw, 64px)`,
                letterSpacing: "-0.45px",
              }}
            >
              {language === "ko" ? (
                <>
                  <span>
                    <span style={{ color: COLORS.sub2 }}>
                      나의 첫 AI 업무 비서
                    </span>
                    <span style={{ color: COLORS.white }}>를</span>
                  </span>
                  <br />
                  <span
                    style={{
                      color: COLORS.white,
                    }}
                  >
                    만나보세요
                  </span>
                </>
              ) : (
                <span>
                  <span style={{ color: COLORS.white }}>Meet Your</span>{" "}
                  <span style={{ color: COLORS.sub2 }}>
                    First AI Task Assistant
                  </span>
                </span>
              )}
            </SlashSubFeatureHeader>
          </Fade>
          <Fade direction="up" triggerOnce={true} duration={1500}>
            <AppDisplayWrapper isMobile={isMobile}>
              <Button
                target="_blank"
                href={
                  language === "ko"
                    ? "https://apps.apple.com/kr/app/slash/id6450197473"
                    : "https://tally.so/r/3qo0Yd"
                }
                sx={{
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: "#121416",
                  border: `1.8px solid ${COLORS.gray800}`,
                  borderRadius: "12px",
                  boxShadow: "0px 7.2px 28.8px 0px rgba(45, 106, 223, 0.08)",
                  cursor: "pointer",
                  marginRight: isMobile ? "12px" : "20px",
                  padding: isMobile ? "12px 24px" : "22px 43px",
                }}
              >
                <AppStore />
                <AppStoreText style={{ marginLeft: "12px" }} />
              </Button>
              <Button
                target="_blank"
                href={
                  language === "ko"
                    ? "https://tally.so/r/mVv29E"
                    : "https://tally.so/r/3qo0Yd"
                }
                sx={{
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: "#121416",
                  border: `1.8px solid ${COLORS.gray800}`,
                  borderRadius: "12px",
                  boxShadow: "0px 7.2px 28.8px 0px rgba(45, 106, 223, 0.08)",
                  cursor: "pointer",
                  padding: isMobile ? "12px 24px" : "22px 43px",
                }}
              >
                <GooglePlay />
                <GooglePlayText style={{ marginLeft: "12px" }} />
              </Button>
            </AppDisplayWrapper>
          </Fade>
        </SlashSubFeatureWrapper>
        <ChatNotificationWrapper isMobile={isMobile}>
          <Fade
            direction="up"
            triggerOnce={true}
            duration={1500}
            style={{
              width: isMobile ? "90%" : "80%",
            }}
          >
            <ChatNotification isMobile={isMobile}>
              <ChatIconWrapper isMobile={isMobile}>
                {isMobile ? <Logo width={47.434} height={17.996} /> : <Logo />}
                <span
                  style={
                    isMobile
                      ? { margin: "8px 0px" }
                      : {
                          fontSize: 24,
                          lineHeight: 0,
                          margin: "0px 8px",
                          marginBottom: "8px",
                        }
                  }
                >
                  X
                </span>
                {isMobile ? (
                  <ChatLogo width={78.857} height={18} fill={COLORS.white} />
                ) : (
                  <ChatLogo width={140.19} height={32} fill={COLORS.white} />
                )}
              </ChatIconWrapper>
              <span
                style={{
                  fontSize: isMobile ? "min(4vw, 13px)" : "min(2vw, 20px)",
                  marginBottom: isMobile ? "20px" : "36px",
                  whiteSpace: "pre-line",
                  lineHeight: isMobile ? "min(6vw, 20px)" : "min(3vw, 30px)",
                  textAlign: "center",
                }}
              >
                {language === "ko"
                  ? `슬래시챗은 SLASH의 컴패니언 앱으로,\n업무의 시작인 메모를 쉽고 간편하게 하도록 돕습니다.`
                  : isMobile
                  ? `SLASH Chat is the companion app to SLASH.\nUse them together to experience even more\npowerful productivity.`
                  : `SLASH Chat is the companion app to SLASH.\nUse them together to experience even more powerful productivity.`}
              </span>
              <Button
                variant="contained"
                size="small"
                href={`https://app.doslash.io/`}
                sx={{
                  backgroundColor: `${COLORS.brand1}`,
                  padding: isMobile ? "14px 16px" : "25.2px 28.8px",
                  borderRadius: "8px",
                  textTransform: "capitalize",
                  fontSize: isMobile ? "min(4vw, 13px)" : "min(2vw, 24px)",
                  fontWeight: 700,
                  letterSpacing: "-0.45px",
                }}
              >
                {language === "ko" ? "SLASH 시작하기" : "Start SLASH"}
              </Button>
            </ChatNotification>
          </Fade>
        </ChatNotificationWrapper>
      </Main>
    </Container>
  );
};

export default Chat;

const Container = styled.div<{ isBigDesktop?: boolean }>`
  ${(props) =>
    props.isBigDesktop &&
    css`
      display: flex;
      flex-direction: column;
      align-items: center;
    `}

  .MuiButton-contained {
    box-shadow: 0px 12px 40px 0px rgba(45, 106, 223, 0.12);
  }
`;

const Wrapper = styled.div<{
  isMobile?: boolean;
  isSmallTablet?: boolean;
  isTablet?: boolean;
}>`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 140px;
  box-sizing: border-box;
  padding: 0px 100px;

  ${(props) =>
    props.isMobile &&
    css`
      padding: 0px 20px;
      margin-top: 80px;
    `}

  ${(props) =>
    props.isSmallTablet &&
    css`
      padding: 0px 10px;
      margin-top: 120px;
    `}

  ${(props) =>
    props.isTablet &&
    css`
      padding: 0px 10px;
      margin-top: 120px;
    `}
`;

const HeroWrapper = styled.div<{ isMobile?: boolean }>`
  padding: 100px 0px;
  ${(props) =>
    props.isMobile &&
    css`
      padding: 10px 20px;
      background-image: url(${require("../images/background.png")});
      background-size: cover;
    `}
`;

const HeroVideo = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const SwiperWrapper = styled.div<{
  isMobile?: boolean;
  isSmallTablet?: boolean;
  isTablet?: boolean;
}>`
  margin-top: 220px;

  .swiper-button-prev {
    left: 23%;
    color: ${COLORS.gray300};
  }

  .swiper-button-next {
    right: 23%;
    color: ${COLORS.gray300};
  }

  .swiper-slide-prev {
    opacity: 0.4;
  }

  .swiper-slide-next {
    opacity: 0.4;
  }

  ${(props) =>
    (props.isTablet || props.isSmallTablet) &&
    css`
      margin-top: 120px;

      .swiper-button-prev {
        left: 8%;
        color: ${COLORS.gray300};
      }

      .swiper-button-prev:after {
        font-size: 42px;
        font-weight: bold;
      }

      .swiper-button-next {
        right: 8%;
        color: ${COLORS.gray300};
      }

      .swiper-button-next:after {
        font-size: 42px;
        font-weight: bold;
      }
    `}

  ${(props) =>
    props.isMobile &&
    css`
      display: flex;
      justify-content: center;
      margin-top: 0px;

      .swiper-button-prev {
        left: 2%;
      }

      .swiper-button-prev:after {
        font-size: 20px;
        font-weight: bold;
      }

      .swiper-button-next {
        right: 2%;
      }

      .swiper-button-next:after {
        font-size: 20px;
        font-weight: bold;
      }
    `}
`;

const Main = styled.div`
  .slash-intro {
    display: flex;
    padding: 0px 20px;
  }

  .slash-main {
    padding: 60px 0px;
  }

  .slash-inbox {
    padding: 0px 20px;
  }

  .slash-planning {
    padding: 0px 20px;
  }

  .slash-timeboxing {
    padding: 0px 20px;
  }

  .slash-linkage {
    padding: 0px 20px;
  }
`;

const MainTextWrapper = styled.div<{
  isMobile?: boolean;
}>`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-top: 175px;

  ${(props) =>
    props.isMobile &&
    css`
      justify-content: center;
      height: auto;
      margin-top: 36px;
    `}
`;

const MainText = styled.div<{
  isMobile?: boolean;
  isSmallTablet?: boolean;
  isTablet?: boolean;
}>`
  margin-top: 40px;
  ${(props) =>
    props.isMobile &&
    css`
      margin-top: 48px;
    `}
`;

const MainChatTextWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const MainImageWrapper = styled.div<{ isMobile?: boolean }>`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${COLORS.gray100};
  margin-top: 96px;

  ${(props) =>
    props.isMobile &&
    css`
      flex-direction: column;
      margin-top: 60px;
      padding-top: 48px;
    `}
`;

const MainFeature = styled.div<{
  isMobile?: boolean;
  isSmallTablet?: boolean;
  isTablet?: boolean;
  isVisibleText?: boolean;
}>`
  width: 100%;
  /* max-width: 1300px; */
  display: flex;
  justify-content: center;
  /* justify-content: space-between; */
  align-items: center;
  padding: 0px 180px;

  .feature-text-wrapper {
    opacity: 0;
  }

  ${(props) =>
    props.isVisibleText &&
    css`
      .feature-text-wrapper {
        opacity: 1;
      }
    `}

  .feature-text-title {
    display: flex;
    align-items: center;
    ${(props) =>
      (props.isMobile || props.isSmallTablet || props.isTablet) &&
      css`
        justify-content: center;
      `}
  }

  .feature-img {
    ${(props) =>
      props.isMobile &&
      css`
        margin-top: 45px;
      `}

    ${(props) =>
      props.isTablet &&
      css`
        margin-top: 60px;
      `}
  }

  ${(props) =>
    (props.isMobile || props.isSmallTablet || props.isTablet) &&
    css`
      flex-direction: column;
      align-items: center;
      padding: 0px;

      .feature-text-wrapper {
        text-align: center;
      }
    `}
`;

const SlashSubFeatureWrapper = styled.div<{
  isMobile?: boolean;
  isSmallTablet?: boolean;
  isTablet?: boolean;
  isBigDesktop?: boolean;
}>`
  margin-top: 70px;
  background-color: ${COLORS.gray900};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 48px;

  ${(props) =>
    props.isTablet &&
    css`
      padding: 120px 80px;
    `}

  ${(props) =>
    props.isSmallTablet &&
    css`
      padding: 120px 60px;
    `}
  
    ${(props) =>
    props.isMobile &&
    css`
      margin-top: 110px;
      padding: 48px 20px;
    `}
`;

const SlashSubFeatureHeader = styled.div`
  color: ${COLORS.gray900};
  font-weight: 700;
  letter-spacing: -0.25px;
  white-space: pre-line;
`;

const SlashSubFeatureContent = styled.div<{
  isSmallTablet?: boolean;
  isMobile?: boolean;
}>`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 40px;

  & div:nth-child(4),
  & div:nth-child(5),
  & div:nth-child(6) {
    margin-top: 16px;
  }

  ${(props) =>
    props.isMobile &&
    css`
      & div:nth-child(3),
      & div:nth-child(4),
      & div:nth-child(5),
      & div:nth-child(6) {
        margin-top: 16px;
      }
    `}

  ${(props) =>
    props.isSmallTablet &&
    css`
      & div:nth-child(3),
      & div:nth-child(4),
      & div:nth-child(5),
      & div:nth-child(6) {
        margin-top: 16px;
      }
    `}
`;

const IconWrapper = styled.div`
  background-color: ${COLORS.sub3};
  border-radius: 8px;
  padding: 4px;
`;

const FeatureTextIcon = styled.div`
  font-size: 24px;
`;

const MainFeatureImgWrapper = styled.div<{
  isMobile?: boolean;
  isSmallTablet?: boolean;
  isTablet?: boolean;
}>`
  width: 30%;

  .planning {
    order: -1;
  }

  ${(props) =>
    (props.isMobile || props.isSmallTablet || props.isTablet) &&
    css`
      width: 100%;
      margin-top: 40px;
    `}
`;

const AppDisplayWrapper = styled.div<{ isMobile?: boolean }>`
  display: flex;
  align-items: center;
  margin-top: 56px;
  ${(props) =>
    props.isMobile &&
    css`
      margin-top: 32px;
    `}
`;

const ChatNotificationWrapper = styled.div<{ isMobile?: boolean }>`
  display: flex;
  justify-content: center;
  background-color: ${COLORS.gray900};
  padding: 44px 0px 48px 0px;

  ${(props) =>
    props.isMobile &&
    css`
      padding: 44px 0px;
    `}
`;

const ChatNotification = styled.div<{ isMobile?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: linear-gradient(180deg, #3e4046 0%, #131416 100%);
  border: 1px solid #3e4046;
  border-radius: 20px;
  color: ${COLORS.white};
  mix-blend-mode: overlay;
  padding: 60px 0px;

  ${(props) =>
    props.isMobile &&
    css`
      padding: 42px 0px;
      flex-direction: column;
    `}
`;

const ChatIconWrapper = styled.div<{ isMobile?: boolean }>`
  display: flex;
  align-items: center;
  margin-bottom: 34.2px;

  ${(props) =>
    props.isMobile &&
    css`
      flex-direction: column;
      margin-bottom: 20px;
    `}
`;
